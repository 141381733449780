<template>
  <!----->
  <div>
    <v-dialog v-model="attachmentDialog" max-width="960px" persistent>
      <template>
        <v-card
          ><v-card-text>
            <div class="text-center">
              <img :src="selectTeeth2Show" />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="attachmentDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <template>
          <!--v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
            Step {{ n }}
          </!--v-stepper-step-->

          <v-stepper-step editable step="1"> Case Information </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step editable step="2"> Doctor Upload </v-stepper-step>

          <!--v-divider></!--v-divider>
          <v-stepper-step editable step="3"> Lab Upload </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step editable step="4"> Package </v-stepper-step>
          -->
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content key="1-content" step="1">
          <v-card class="mb-12" color="lighten-1">
            <v-card-text>
              <!-- doctor/clinic info -->
              <v-card>
                <v-card-title>Doctor & Clinic Name</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.doctor"
                          :rules="[(v) => !!v || 'This field is required']"
                          label="Doctor Name"
                          required
                          id="doctorName"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.clinicName"
                          label="Clinic Name"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          id="clinicName"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <!-- patient info -->
              <div style="height: 20px"></div>
              <v-card>
                <v-card-title>Patient Infomation</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.firstname"
                          :rules="[(v) => !!v || 'This field is required']"
                          label="Firstname"
                          required
                          id="firstname"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.lastname"
                          :rules="[(v) => !!v || 'This field is required']"
                          label="Lastname"
                          required
                          id="lastname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!--v-row>
                      <v-col md="6" sm="12">
                        <v-menu
                          ref="birthdate"
                          v-model="birthdate"
                          :close-on-content-click="false"
                          :return-value.sync="eForm.birthdate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="eForm.birthdate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              required="required"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="eForm.birthdate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="birthdate = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.birthdate.save(eForm.birthdate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.race"
                          label="Race"
                          id="race"
                        ></v-text-field>
                      </v-col>
                    </@v-row-->
                    <v-row>
                      <v-col md="6" sm="12">
                        <p class="text-left">Gender</p>
                        <v-radio-group v-model="eForm.gender" mandatory>
                          <v-radio label="Male" value="male"></v-radio>
                          <v-radio label="Female" value="female"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <!-- patient info -->
              <div style="height: 20px"></div>
              <v-card>
                <v-card-title>Case Infomation</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="eForm.chief_complaint"
                          label="Chief Complaint"
                          clearable
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="eForm.treatment_goal"
                          label="Treatment Goal"
                          clearable
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-container>
                    <v-row>
                      <v-col sm="12" md="12" class="text-left">
                        Treat Which Arch(s)? (Check 'UPPER & LOWER' for both arches)
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          <v-checkbox
                            v-model="eForm.TreatWhichArch"
                            label="UPPER Only"
                            true-value="UPPER_ONLY"
                            value="UPPER_ONLY"
                          ></v-checkbox>

                          Case Type:
                          <v-radio-group
                            v-model="eForm.upper_only_data"
                            :disabled="eForm.TreatWhichArch != 'UPPER_ONLY'"
                          >
                            <v-radio
                              label="Non-Extraction"
                              value="Non-Extraction"
                            ></v-radio>
                            <v-radio label="Extraction" value="Extraction"></v-radio>
<v-radio label="Starter" value="Starter"></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          <v-checkbox
                            v-model="eForm.TreatWhichArch"
                            label="LOWER Only"
                            true-value="LOWER_ONLY"
                            value="LOWER_ONLY"
                          ></v-checkbox>
                          Case Type:

                          <v-radio-group
                            v-model="eForm.lower_only_data"
                            :disabled="eForm.TreatWhichArch != 'LOWER_ONLY'"
                          >
                            <v-radio
                              label="Non-Extraction"
                              value="Non-Extraction"
                            ></v-radio>
                            <v-radio label="Extraction" value="Extraction"></v-radio>
<v-radio label="Starter" value="Starter"></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          <v-checkbox
                            v-model="eForm.TreatWhichArch"
                            label="UPPER & LOWER"
                            true-value="UPPER_LOWER"
                            value="UPPER_LOWER"
                          ></v-checkbox>
                          Case Type:

                          <v-radio-group
                            v-model="eForm.upper_lower_data"
                            :disabled="eForm.TreatWhichArch != 'UPPER_LOWER'"
                          >
                            <v-radio
                              label="Non-Extraction"
                              value="Non-Extraction"
                            ></v-radio>
                            <v-radio label="Extraction" value="Extraction"></v-radio>
<v-radio label="Starter" value="Starter"></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>

                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          Teeth To Extract:
                          <v-radio-group v-model="eForm.teeth2Extract">
                            <v-radio label="Please select" value="Select"></v-radio>
                          </v-radio-group>

                          <div style="height: 20px">
                            <!-- image -->
                            <div>
                              <ul class="bfc_li_teeth2extract">
                                <li v-for="n in 8" :key="n">
                                  {{ 19 - n }}
                                </li>
                              </ul>
                              <ul class="bfc_li_teeth2extract" style="margin-left: 100px">
                                <li v-for="n in 8" :key="n">
                                  {{ 20 + n }}
                                </li>
                              </ul>
                              <div>
                                <ul class="bfc_li_teeth2extract">
                                  <li
                                    v-for="n in 8"
                                    :key="n"
                                    class="bfc_li_teeth2extract_cbx"
                                  >
                                    <input
                                      type="checkbox"
                                      :value="19 - n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </li>
                                </ul>
                                <ul
                                  class="bfc_li_teeth2extract"
                                  style="margin-left: 100px"
                                >
                                  <li
                                    v-for="n in 8"
                                    :key="n"
                                    class="bfc_li_teeth2extract_cbx"
                                  >
                                    <input
                                      type="checkbox"
                                      :value="20 + n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </li>
                                </ul>
                              </div>
                              <div class="text-left" style="margin-left: 120px">
                                <img src="../../assets/teeth-2-extract/UpperTeeth.png" />
                                <br />
                                <img src="../../assets/teeth-2-extract/LowerTeeth.png" />
                              </div>
                              <ul class="bfc_li_teeth2extract">
                                <li v-for="n in 8" :key="n">
                                  {{ 49 - n }}
                                </li>
                              </ul>
                              <ul class="bfc_li_teeth2extract" style="margin-left: 100px">
                                <li v-for="n in 8" :key="n">
                                  {{ 30 + n }}
                                </li>
                              </ul>
                              <div>
                                <ul class="bfc_li_teeth2extract">
                                  <li
                                    v-for="n in 8"
                                    :key="n"
                                    class="bfc_li_teeth2extract_cbx"
                                  >
                                    <input
                                      type="checkbox"
                                      :value="49 - n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </li>
                                </ul>
                                <ul
                                  class="bfc_li_teeth2extract"
                                  style="margin-left: 100px"
                                >
                                  <li
                                    v-for="n in 8"
                                    :key="n"
                                    class="bfc_li_teeth2extract_cbx"
                                  >
                                    <input
                                      type="checkbox"
                                      :value="30 + n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div style="height: 480px"></div>
                          <v-radio-group v-model="eForm.teeth2Extract">
                            <v-radio
                              label="Not sure, please propose"
                              value="PleasePropose"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                        <v-divider></v-divider>
                        <v-container>
                          <div v-for="item in caseFormDental" :key="item.key">
                            {{ item.subject }}
                            <div v-for="val in item.values" :key="val.key">
                              <v-checkbox
                                :value="val.text"
                                :label="val.text"
                                v-model="eForm[item.key]"
                              >
                              </v-checkbox>
                              <v-text-field
                                v-model="eForm.caseFormDental[item.key][val.key]"
                                placeholder="e.g 11, 12, 17, 18"
                                v-if="
                                  eForm[item.key].findIndex((elem) => elem == val.text) !=
                                  -1
                                "
                              ></v-text-field>
                            </div>
                          </div>
                        </v-container>
                        <v-divider></v-divider>
                        <v-container>
                          <div>
                            Work Only On ( Tick All if required )
                            <v-checkbox
                              v-for="item in caseStructure.workOnlyOn"
                              :key="item"
                              :value="item"
                              :label="item"
                            ></v-checkbox>
                          </div>
                        </v-container>

                        <v-divider></v-divider>
                        <v-container>
                          <div>
                            To Correct Midline
                            <v-radio-group v-model="eForm.ToCollectMidline" row>
                              <v-radio
                                v-for="item in caseStructure.toCollectMidline.radio"
                                :key="item"
                                :label="item"
                                :value="item"
                                @click="toCollectMidlineHandle"
                              ></v-radio>
                            </v-radio-group>
                            <div
                              v-for="item in caseStructure.toCollectMidline.childs"
                              :key="item.key"
                            >
                              {{ item.title }}
                              <v-radio-group
                                v-model="eForm[item.key]"
                                :disabled="eForm.ToCollectMidline == 'No'"
                              >
                                <v-radio
                                  v-for="idx in item.radio"
                                  :key="idx"
                                  :value="idx"
                                  :label="idx"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div v-for="item in caseStructure.radioBoxForm" :key="item.key">
                            {{ item.title }}
                            <div>
                              <v-radio-group v-model="eForm[item.key]" row>
                                <v-radio
                                  v-for="val in item.values"
                                  :key="val"
                                  :label="val"
                                  :value="val"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div
                            v-for="item in caseStructure.ToCorrectSpacing"
                            :key="item.key"
                          >
                            {{ item.title }}
                            <v-radio-group v-model="eForm[item.key].value" row>
                              <v-radio
                                v-for="val in item.values"
                                :key="val"
                                :label="val"
                                :value="val"
                              ></v-radio>
                            </v-radio-group>
                            <div v-for="child in item.childs" :key="child.key">
                              {{ child.title }}

                              <v-radio-group v-model="eForm[item.key][child.key]" column>
                                <v-radio
                                  v-for="(val, index) in child.values"
                                  :key="index"
                                  :label="val"
                                  :value="val"
                                  :disabled="eForm.ToCorrectSpacing.value == 'No'"
                                ></v-radio>
                              </v-radio-group>

                              <v-text-field
                                v-model="eForm.ToCorrectSpacing.TypeofSpaceClosure_other"
                                label="Special Instructions/Notes On Correct Spacing (if any)"
                                id="TypeofSpaceClosure_other"
                                readonly
                              ></v-text-field>
                            </div>
                          </div>
                        </v-container>

                        <!---------------->

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div
                            v-for="item in caseStructure.ToCorrectCrowding"
                            :key="item.key"
                          >
                            {{ item.title }}
                            <v-radio-group v-model="eForm[item.key].value" row>
                              <v-radio
                                v-for="val in item.values"
                                :key="val"
                                :label="val"
                                :value="val"
                              ></v-radio>
                            </v-radio-group>
                            <div v-for="child in item.childs" :key="child.key">
                              {{ child.title }}

                              <v-checkbox
                                v-for="(val, index) in child.values"
                                v-model="eForm[item.key][child.key]"
                                :key="index"
                                :label="val"
                                :value="val"
                                :disabled="eForm.ToCorrectCrowding.value == 'No'"
                              ></v-checkbox>

                              <v-text-field
                                v-model="eForm.ToCorrectSpacing.TypeofSpaceClosure_other"
                                label="Special Instructions/Notes On Correct Spacing (if any)"
                                id="TypeofSpaceClosure_other"
                                readonly
                              ></v-text-field>
                            </div>

                            <v-container fluid>
                              Relieve Crowding By Stripping:
                              <v-radio-group
                                v-model="
                                  eForm.ToCorrectCrowding.RelieveCrowdingByStripping
                                "
                              >
                                <v-radio label="Please select" value="Select"></v-radio>
                              </v-radio-group>

                              <div style="height: 20px">
                                <!-- image -->
                                <div>
                                  <div>
                                    <ul class="bfc_li_RelieveCrowdingByStripping">
                                      <li
                                        v-for="n in 11"
                                        :key="n"
                                        class="bfc_li_RelieveCrowdingByStripping_cbx padding22"
                                      >
                                        <input
                                          type="checkbox"
                                          :value="19 - n"
                                          v-model="
                                            eForm.ToCorrectCrowding
                                              .bfc_li_RelieveCrowdingByStripping_cbx
                                          "
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="text-left" style="margin-left: 120px">
                                    <img
                                      src="../../assets/correct-crowding/all_teeth2.png"
                                    />
                                  </div>

                                  <div>
                                    <ul
                                      class="bfc_li_RelieveCrowdingByStripping mandible"
                                    >
                                      <li
                                        v-for="n in 11"
                                        :key="n"
                                        class="bfc_li_RelieveCrowdingByStripping_cbx padding18"
                                      >
                                        <input
                                          type="checkbox"
                                          :value="49 - n"
                                          v-model="
                                            eForm.ToCorrectCrowding
                                              .bfc_li_RelieveCrowdingByStripping_cbx
                                          "
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div style="height: 240px"></div>
                              <v-radio-group
                                v-model="
                                  eForm.ToCorrectCrowding.RelieveCrowdingByStripping
                                "
                              >
                                <v-radio
                                  label="Not sure, please propose"
                                  value="PleasePropose"
                                ></v-radio>
                              </v-radio-group>
                            </v-container>
                          </div>
                        </v-container>

                        <!---------------->

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div v-for="item in caseStructure.ModifyTorque" :key="item.key">
                            {{ item.title }}
                            <div>
                              <v-radio-group v-model="eForm[item.key].value" row>
                                <v-radio
                                  v-for="val in item.values"
                                  :key="val"
                                  :label="val"
                                  :value="val"
                                  @click="ModifyTorqueHandle"
                                ></v-radio>
                              </v-radio-group>

                              <div v-for="child in item.childs" :key="child.key">
                                <v-text-field
                                  v-model="eForm.ModifyTorque.WhichTeethandAmount"
                                  :label="child.title"
                                  :id="child.key"
                                  :readonly="eForm[item.key].value != 'Yes'"
                                ></v-text-field>
                              </div>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div
                            v-for="item in caseStructure.ApplyRotation"
                            :key="item.key"
                          >
                            {{ item.title }}
                            <div>
                              <v-radio-group v-model="eForm[item.key].value" row>
                                <v-radio
                                  v-for="val in item.values"
                                  :key="val"
                                  :label="val"
                                  :value="val"
                                  @click="ApplyRotationHandle"
                                ></v-radio>
                              </v-radio-group>

                              <div v-for="child in item.childs" :key="child.key">
                                <v-text-field
                                  v-model="eForm.ApplyRotation.WhichTeethandAmount"
                                  :label="child.title"
                                  :id="child.key"
                                  :readonly="eForm[item.key].value != 'Yes'"
                                ></v-text-field>
                              </div>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div>
                            Attachment:
                            <v-radio-group v-model="eForm.Attachment">
                              <v-radio
                                label="Not sure, please propose"
                                value="PleasePropose"
                              ></v-radio>
                              <v-radio label="Please select" value="Select"></v-radio>
                            </v-radio-group>
                          </div>
                          <div>
                            <v-container>
                              <v-row>
                                <v-col sm="12" cols="12" md="12" class="attachment"
                                  ><ul v-for="m in 8" :key="19 - m">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li
                                      @click="
                                        selectAttachment(
                                          displayToothImage(
                                            'teeth/teeth-' + (19 - m) + '.png',
                                            19 - m
                                          )
                                        )
                                      "
                                    >
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (19 - m) + '.png',
                                            19 - m
                                          )
                                        "
                                        :alt="'image teeth-' + (19 - m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                  <ul v-for="m in 8" :key="m + 20">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li
                                      @click="
                                        selectAttachment(
                                          displayToothImage(
                                            'teeth/teeth-' + (20 + m) + '.png',
                                            20 + m
                                          )
                                        )
                                      "
                                    >
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (20 + m) + '.png',
                                            20 + m
                                          )
                                        "
                                        :alt="'image teeth-' + (20 + m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="12" cols="12" md="12" class="attachment"
                                  ><ul v-for="m in 8" :key="49 - m">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li
                                      @click="
                                        selectAttachment(
                                          displayToothImage(
                                            'teeth/teeth-' + (49 - m) + '.png',
                                            49 - m
                                          )
                                        )
                                      "
                                    >
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (49 - m) + '.png',
                                            49 - m
                                          )
                                        "
                                        :alt="'image teeth-' + (49 - m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                  <ul v-for="m in 8" :key="30 + m">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li
                                      @click="
                                        selectAttachment(
                                          displayToothImage(
                                            'teeth/teeth-' + (30 + m) + '.png',
                                            30 + m
                                          )
                                        )
                                      "
                                    >
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (30 + m) + '.png',
                                            30 + m
                                          )
                                        "
                                        :alt="'image teeth-' + (30 + m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-co>
                                  <v-card-text>
                                    <ul>
                                      <li
                                        v-for="color in attachmentCfg.color"
                                        :key="color"
                                      >
                                        <div
                                          class="box"
                                          :style="
                                            'background-color:' +
                                            color.code +
                                            ';padding-left:10px'
                                          "
                                        >
                                          {{ color.size }}
                                        </div>
                                      </li>
                                    </ul></v-card-text
                                  >
                                </v-co>
                              </v-row>
                            </v-container>
                          </div>

                          <!-- div lower -->
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-btn color="primary" @click="nextStep(1)"> Next </v-btn>

          <!--v-btn text @click="backStep(1)"> Back </!--v-btn-->
        </v-stepper-content>
        <!--upload step -->
        <v-stepper-content key="2-content" step="2">
          <div class="mx-12">
            <div class="uploadForm">
              <v-row>
                <v-col md="4" sm="6">
                  <dragdrop-upload
                    v-bind:uploadName="rightProfile"
                    uploadId="rightProfile"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.rightProfile"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6">
                  <dragdrop-upload
                    v-bind:uploadName="frontalRepose"
                    uploadId="frontalRepose"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.frontalRepose"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="frontalSmiling"
                    uploadId="frontalSmiling"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.frontalSmiling"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="anteriorFrontal"
                    uploadId="anteriorFrontal"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.anteriorFrontal"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="buccalRight"
                    uploadId="buccalRight"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.buccalRight"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="buccalLeft"
                    uploadId="buccalLeft"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.buccalLeft"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="occlusalUpper"
                    uploadId="occlusalUpper"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.occlusalUpper"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="occlusalLower"
                    uploadId="occlusalLower"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.occlusalLower"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="opg"
                    uploadId="opg"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.opg"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="latCeph"
                    uploadId="latCeph"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.latCeph"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="others"
                    uploadId="others"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.others"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>

                <v-col md="4" sm="6"
                  ><dragdrop-upload
                    v-bind:uploadName="others2"
                    uploadId="others2"
                    @updateUpload="updateUpload"
                    @removeUpload="removeUpload"
                    v-bind:imgData="eForm.picOfPatient.others2"
                    v-bind:imgUrl="eForm.picOfPatientURI"
                  ></dragdrop-upload>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mx-12">
            <!--v-btn color="primary" @click="nextStep(2)"> Next </v-btn-->

            <v-btn text @click="backStep(1)"> Back </v-btn>
          </div>
        </v-stepper-content>

        <!-- payment >
        <v-stepper-content-- key="3-content" step="3">
          <div class="mx-12">
            <div class="uploadForm">
              <dragdrop-upload
                v-bind:uploadName="rightProfile"
                uploadId="rightProfile"
                @labUpload="updateUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.rightProfile"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="frontalRepose"
                uploadId="frontalRepose"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.frontalRepose"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="frontalSmiling"
                uploadId="frontalSmiling"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.frontalSmiling"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="anteriorFrontal"
                uploadId="anteriorFrontal"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.anteriorFrontal"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="buccalRight"
                uploadId="buccalRight"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.buccalRight"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="buccalLeft"
                uploadId="buccalLeft"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.buccalLeft"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="occlusalUpper"
                uploadId="occlusalUpper"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.occlusalUpper"
              ></dragdrop-upload>
              <dragdrop-upload
                v-bind:uploadName="occlusalLower"
                uploadId="occlusalLower"
                @updateUpload="labUpload"
                @removeUpload="removeLabUpload"
                v-bind:imgData="eForm.labUpload.occlusalLower"
              ></dragdrop-upload>
            </div>
          </div>
          <v-divider></v-divider>
          <div style="margin-top: 20px">
            <v-btn color="primary" @click="saveLabUpload"> Save Upload </v-btn>

            <v-btn color="primary" @click="nextStep(3)"> Next </v-btn>
            <v-btn text @click="backStep(2)"> Back </v-btn>
          </div>
        </v-stepper-content-->
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dragdropdUpload from "../../library/dragdrop-upload/dragdrop-upload";
//import paymentComponent from "../payment/payment";
import SystemBar from "../../library/system-bar/system-bar";
import LabMenu from "../menubar/menubar";
import axios from "axios";

//import { bfcPackage } from "../../config/config.js";

// eslint-disable-next-line no-unused-vars
import { bfcPackage, attachmentCfg } from "../../config/config.js";
//import teethAttachment from "../../library/attachment/attachment";
import { isAttachmentExists } from "../../../../plugins/libs";

export default {
  components: {
    "dragdrop-upload": dragdropdUpload,
    "lab-menu": LabMenu,
    "bfc-bar": SystemBar,
    //"teeth-attachment": teethAttachment,
  },
  data: () => {
    return {
      packages: bfcPackage,
      attachmentCfg: attachmentCfg,
      attachmentDialog: false,
      selectTeeth2Show: "",
      showAttachment: false,
      teethNo: 0,
      caseId: "",
      caseNo: "",
      custId: "",
      doctorId: "",
      clinicId: "",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      actionCase: "Create Draft",

      rightProfile: "Right Profile",
      frontalRepose: "Frontal Repose",
      frontalSmiling: "Frontal Smiling",
      anteriorFrontal: "Anterior Frontal",
      buccalRight: "Buccal Right",
      buccalLeft: "Buccal Left",
      occlusalUpper: "Occlusal Upper",
      occlusalLower: "Occlusal Lower",
      opg: "OPG",
      latCeph: "Lat Ceph",
      others: "Others",
      others2: "Others 2",
      caseFormDental: [
        {
          subject: "Dental Condition (Please indicate the tooth for each condition)",
          key: "DentalCondition",
          values: [
            {
              key: "DeciduousTeeth",
              text: "Deciduous teeth",
              childText: true,
            },
            {
              key: "Supernumerary",
              text: "Supernumerary",
              childText: true,
            },
            {
              key: "MissingTeeth",
              text: "Missing teeth",
              childText: true,
            },
            {
              key: "BuriedTeeth",
              text: "Buried teeth",
              childText: true,
            },
            {
              key: "ImpactedTeeth",
              text: "Impacted teeth",
              childText: true,
            },
          ],
        },
        {
          subject: "Dental Restoration (Please indicate the tooth for each restoration)",
          key: "DentalRestoration",
          values: [
            {
              key: "AmalgamFilling",
              text: "Amalgam filling",
              childText: true,
            },
            {
              key: "ToothColouredFillingCompositeGLC",
              text: "Tooth coloured filling - composite/GLC",
              childText: true,
            },
            {
              key: "InlayAndOnlayMetalCeramicOrZirconia",
              text: "Inlay and onlay - metal, ceramic, or Zirconia",
              childText: true,
            },
            {
              key: "CrownMetalCeramicZirconia",
              text: "Crown - metal, ceramic, Zirconia",
              childText: true,
            },
            {
              key: "RCTTeeth",
              text: "RCT teeth",
              childText: true,
            },
          ],
        },
      ],
      caseStructure: {
        workOnlyOn: ["Posterior", "Antierior"],
        toCollectMidline: {
          radio: ["Yes", "No"],
          childs: [
            {
              title: "By Shifting Maxillary",
              key: "ByShiftingMaxillary",
              radio: ["To Patient's Left", "To Patient's Right"],
            },
            {
              title: "By Shifting Mandibular",
              key: "ByShiftingMandibular",
              radio: ["To Patient's Left", "To Patient's Right"],
            },
          ],
        },
        radioBoxForm: [
          {
            key: "toCorrectOverBite",
            title: "To Correct OverBite",
            values: ["Yes", "No"],
          },
          { key: "toCorrectOverJet", title: "To Correct OverJet", values: ["Yes", "No"] },
          {
            key: "idealOverBiteOverJetis",
            title: "Ideal OverBite/OverJet is",
            values: ["2mm", "3mm", "4mm"],
          },
        ],
        //
        ToCorrectSpacing: [
          {
            key: "ToCorrectSpacing",
            title: "To Correct Spacing",
            values: ["Yes", "No"],
            childs: [
              {
                key: "TypeofSpaceClosure",
                title: "Type of Space Closure",
                values: [
                  "Type A (close incisal spaces and shift back anterior segment)",
                  "Type B (shift back anterior, shift forward posterior segment)",
                  "Type C (shift forward posterior segment only)",
                  "Others (Please put details in the Instructions/Notes box below)",
                ],
              },
            ],
          },
        ],
        //
        ToCorrectCrowding: [
          {
            key: "ToCorrectCrowding",
            title: "To Correct Crowding",
            values: ["Yes", "No"],
            childs: [
              {
                key: "RelieveCrowdingBy",
                title: "Relieve crowding by",
                values: ["Stripping", "Expansion", "Flaring of Incisors"],
              },
            ],
          },
        ],
        //
        // text
        ModifyTorque: [
          {
            key: "ModifyTorque",
            title: "Modify Torque",
            values: ["Yes", "No"],
            childs: [
              {
                key: "WhichTeethandAmount",
                title: "Which Teeth and Amount?",
              },
            ],
          },
        ],
        // text
        //
        ApplyRotation: [
          {
            key: "ApplyRotation",
            title: "Apply Rotation",
            values: ["Yes", "No"],
            childs: [
              {
                key: "WhichTeethandAmount",
                title: "Which Teeth and Amount?",
              },
            ],
          },
        ],
      },

      doctor: {},
      attachmentData: [],
      eForm: {
        attachmentData: [],
        doctor: {},
        DentalCondition: [],
        DentalRestoration: [],
        teeth2extract_cbx: [],
        workOnlyOn: [],
        ToCollectMidline: "",
        ByShiftingMaxillary: "",
        ByShiftingMandibular: "",
        TreatWhichArch: "",
        ToCorrectSpacing: {
          value: "",
          TypeofSpaceClosure: "",
          TypeofSpaceClosure_other: "",
        },
        //
        ToCorrectCrowding: {
          value: "",
          RelieveCrowdingBy: [],
          RelieveCrowdingBy_other: "",
          RelieveCrowdingByStripping: "",
          RelieveCrowdingByStrippingTeeth: [],
          NoteonStrippingOfMaxilla: "",
          NoteonStrippingOfMandible: "",
          bfc_li_RelieveCrowdingByStripping_cbx: [],
        },
        // text
        ModifyTorque: {
          value: "",
          WhichTeethandAmount: "",
        },
        // text
        ApplyRotation: {
          value: "",
          WhichTeethandAmount: "",
        },
        //
        attachment: "",
        attachment_cbx: [],
        picOfPatientURI: {},
        picOfPatient: {
          rightProfile: "",
          frontalRepose: "",
          frontalSmiling: "",
          anteriorFrontal: "",
          buccalRight: "",
          buccalLeft: "",
          occlusalUpper: "",
          occlusalLower: "",
          opg: "",
          latCeph: "",
          others: "",
          others2: "",
        },
        labUpload: {
          rightProfile: "",
          frontalRepose: "",
          frontalSmiling: "",
          anteriorFrontal: "",
          buccalRight: "",
          buccalLeft: "",
          occlusalUpper: "",
          occlusalLower: "",
        },
      },
      birthdate: "",
      e1: 1,
      nSteps: 2,
      teeth2extract_cbx: [],
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/lab/dashboard",
        },
      ],
    };
  },
  mounted() {
    this.init();
    // this.packages = bfcPackage;
    // console.log(this.packages);
  },
  computed: {
    ...mapGetters({
      //getAuth: "BFC/getAuth",
      //getDoctorInfo: "BFC/getDoctorInfo",
      //getCaseInfo: "BFC/getCaseInfo",
    }),
    info() {
      //console.log(this.$route.params.caseNo);
      return this.getCaseInfo(this.$route.params.caseNo);
    },
    caseData() {
      return this.eForm;
    },
  },
  methods: {
    ...mapActions({
      signout: "BFC/Logout",
      ///createDraft: "BFC/createDraftCase",
      //removeImage: "BFC/removeDoctorDraftImage",
    }),
    closeAttachment() {
      this.showAttachment = false;
    },
    async DraftStep(n) {
      // check require
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    async toPayment(n) {
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    nextStep(n) {
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    backStep(n) {
      if (n === 0) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    async init() {
      this.breadcrumbs.push({
        text: "Case : " + this.$route.params.caseNo,
        disabled: true,
        href: "#",
      });
      // //console.log(this.info);
      // //this.eForm = this.info;
      // console.log(this.eForm);
      // if (this.eForm.length == 0) {
      //   //this.logout();
      // }
      // //console.log(this.doctor);
      // //this.eForm.doctor =
      // //this.doctor.member[0].firstname + " " + this.doctor.member[0].lastname;
      // this.custId = this.eForm.member[0].custId;
      // this.doctorId = this.eForm.doctorId;
      // this.clinicId = this.eForm.clinicId;
      // //this.eForm.clinicName = this.doctor.clinicName;

      // //    this.doctor
      try {
        //let payload
        await axios
          .post(
            this.url + "/api/bfc/v1/case/caseInfo",
            { caseNo: this.$route.params.caseNo }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              let info = response.data.data[0];

              this.eForm = info.caseDetail;
              this.attachmentData =
                typeof this.eForm.attachmentData === "undefined"
                  ? []
                  : this.eForm.attachmentData;
              //this.eForm.doctor =
              info.doctorId.memberId.firstname + " " + info.doctorId.memberId.lastname;
              //this.eForm.clinicName = info.clinicId.name;
              console.log(this.eForm);
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    logout() {
      this.signout();
      this.$router.push("/lab");
    },
    toCollectMidlineHandle() {
      //console.log(this.eForm.ToCollectMidline);
      if (this.eForm.ToCollectMidline == "No") {
        this.eForm.ByShiftingMaxillary = "";
        this.eForm.ByShiftingMandibular = "";
      }
    },
    toCollectSpacingHandle() {
      //console.log(this.eForm.ToCollectMidline);
      if (this.eForm.ToCorrectSpacing.value == "No") {
        this.eForm.TypeofSpaceClosure = "";
        this.eForm.TypeofSpaceClosure_other = "";
      }
    },
    ModifyTorqueHandle() {
      //console.log(this.eForm.ModifyTorque.value);
      if (this.eForm.ModifyTorque.value == "No") {
        this.eForm.ModifyTorque.WhichTeethandAmount = "";
      }
    },
    ApplyRotationHandle() {
      //console.log(this.eForm.ModifyTorque.value);
      if (this.eForm.ApplyRotation.value == "No") {
        this.eForm.ApplyRotation.WhichTeethandAmount = "";
      }
    },
    updateUpload(val) {
      console.log(val);
      this.eForm.picOfPatient[val.id] = val.image;
      this.createDraft(this.eForm);
      //console.log(this.getDraft);
    },
    removeUpload(val) {
      this.eForm.picOfPatient[val.id] = "";
      this.removeImage(val);
      //console.log(this.getDraft);
      //this.remove(this.eForm);
    },

    updateLabUpload(val) {
      console.log(val);
      this.eForm.labUpload[val.id] = val.image;
      this.createDraft(this.eForm);
      //console.log(this.getDraft);
    },
    removeLabUpload(val) {
      this.eForm.labUpload[val.id] = "";
      this.removeImage(val);
      //console.log(this.getDraft);
      //this.remove(this.eForm);
    },
    selectAttachment(teeth) {
      //console.log(teeth);
      this.attachmentDialog = true;
      this.selectTeeth2Show = teeth;
      //console.log(this.showAttachment);
    },
    selectAttachmentTeeth(attachmentInfo) {
      //console.log(attachmentInfo);
      console.log(attachmentInfo);
      //let prepareObj = {};
      //this.$set(this.attachmentData, attachmentInfo.teethNo, attachmentInfo);
      //this.attachment_cbx.push({attachmentInfo.teethNo: attachmentInfo};
      //this.attachmentData;
      //console.log(typeof this.attachmentData);
      // if (!isAttachmentExists(this.attachmentData, attachmentInfo.teethNo)) {
      //   //
      //   this.attachmentData.push(attachmentInfo);
      // } else {
      //   this.attachmentData = this.attachmentData.filter((item) => {
      //     return item.teethNo !== attachmentInfo.teethNo;
      //   });
      //   this.attachmentData.push(attachmentInfo);
      // }
      //console.log(this.attachmentData);
    },
    displayToothImage(teeth, teethNo) {
      //console.log(teethNo);
      //console.log(this.attachmentData);
      //console.log(require(`../../assets/${teeth}`));
      let selectTeeth = require(`../../assets/${teeth}`);
      if (isAttachmentExists(this.attachmentData, teethNo)) {
        let selectTeethPath = this.attachmentData
          .filter((item) => {
            return item.teethNo == teethNo;
          })
          .map((item) => {
            return item.path + item.imgName;
          });
        selectTeeth = require(`../../assets/${selectTeethPath}`);
      }
      //console.log(selectTeeth);
      return selectTeeth;
    },
    //----------------------
    saveLabUpload() {},
    async savePackage() {
      let selectPackage = this.packages.filter((item) => {
        // console.log(item);
        // console.log("--+++++----");
        // console.log(this.eForm.package);
        return item.value == this.eForm.package;
      });
      selectPackage[0].packageStatus = "Waiting";
      //console.log(selectPackage);
      try {
        let payload = { caseNo: this.$route.params.caseNo, package: selectPackage[0] };
        //console.log(payload);
        //console.log(selectPackage);
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/updatePackage",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            if (response.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
  },
  watch: {
    attachmentDialog: {
      //console.log();
      handler(val) {
        console.log("--------------------");
        console.log(val);
      },
    },
    // eForm: {
    //   //console.log();
    //   handler(val) {
    //     console.log(val);
    //     console.log("--------------------");
    //     //console.log(this.eForm.labUpload);
    //   },
    // },
  },
};
</script>
<style scope type="css">
.bfc_li_teeth2extract,
.bfc_li_teeth2extract li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_teeth2extract_cbx {
  margin-left: 5.4px;
}

.bfc_li_RelieveCrowdingByStripping {
  display: inline;
  margin-left: 175px;
}

.bfc_li_RelieveCrowdingByStripping.mandible {
  display: inline;
  margin-left: 210px;
}

.bfc_li_RelieveCrowdingByStripping li {
  font-size: 0.97em;
  display: inline;
}

/* .bfc_li_RelieveCrowdingByStripping_cbx {
  /*padding-left: 20px;
} */
.padding14 {
  padding-left: 14px;
}
.padding16 {
  padding-left: 16px;
}

.padding19 {
  padding-left: 19px;
}

.padding18 {
  padding-left: 19px;
}

.padding22 {
  padding-left: 22px;
}

.padding24 {
  padding-left: 24px;
}

.padding26 {
  padding-left: 26px;
}

.padding30 {
  padding-left: 30px;
}

/************/
.attachment {
  position: relative !important;
  display: block !important;
  width: 220px !important;
  height: auto;
  float: left;
}
.attachment ul {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  /* float: left; */
  margin-block-start: em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.attachment li {
  font-size: 0.97em !important;
  padding-left: 6px;
}

.attachment li img {
  max-width: 62px;
  cursor: pointer;
}
.attachment li:nth-child(2n-1) {
  background-color: #dfdfdf;
  width: 100%;
}

.quadrant {
  position: relative !important;
  display: inline-block !important;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  /* float: left; */
}
.quadrant li {
  font-size: 0.97em !important;
  text-align: center;
  margin-left: -32px;
  margin-right: 32px;
}
.uploadForm {
  display: block;
  width: 100% !important;
}
</style>
